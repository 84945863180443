console.log("1Doc integration says hi!")

//var Promise = TrelloPowerUp.Promise;

var BLACK_ROCKET_ICON = 'https://cdn.glitch.com/1b42d7fe-bda8-4af8-a6c8-eff0cea9e08a%2Frocket-ship.png?1494946700421';

TrelloPowerUp.initialize({
	'card-from-url': async function(t, options) {
		// options.url has the url in question
		// if we know cool things about that url we can give Trello a name and desc
		// to use when creating a card. Trello will also automatically add that url
		// as an attachment to the created card
		// As always you can return a Promise that resolves to the card details
		t.alert(options.url)
		if (options.url.indexOf('https://sergas.1doc.com.br/') == 0){
			return (async () => {
				// console.log('request is trying to run')
				const regex = /hash=([0-9A-F]{24})/
				
				// Get secret values
				let server = ''
				let token = ''
				try{
					server = await t.loadSecret('server')
					token = await t.loadSecret('token')
				}catch(error) {}

				let hash = options.url.match(regex)[1]
				let data = await fetch(`https://t1d.sergipegas.com.br:5000/makecard/${hash}?api_key=${token}`)
				return await data.json()
			})();
		}
		// Not my url
		throw t.NotHandled();
	},
	'card-badges': async function(t, opts){
				
		let claimed = opts.attachments.filter(function (attachment) {
			return attachment.url.indexOf('https://sergas.1doc.com.br/') == 0
		})

		let makeCard = async () => {
			const regex = /hash=([0-9A-F]{24})/
			let hashs = claimed.map((element) => {
				return element.url.match(regex)[1]
			})

			return hashs.map((e) => {
				return {
					text: `Integrado com 1Doc`,
					color: 'blue'
				}
			})
		}

		return makeCard()
	},
	'attachment-sections': async function(t, opts){
		const processed = opts.entries.map((attachment) => {
			
			const first_test = attachment.url.indexOf('https://sergas.1doc.com.br/') == 0
			
			const regex = /hash=([0-9A-F]{24})/
			const match = attachment.url.match(regex)

			return {
				'valid': first_test && !!match && match.length > 1,
				'hash': match[1],
				'url': attachment.url,
				'attachment': attachment,
			}
		})
		

		const claimed_objs = processed.filter(e => e.valid);
		const claimed = claimed_objs.map(e => e.attachment);

		if (claimed_objs.length > 0) {
			return [{
				id: "Integração_1Doc",
				claimed: claimed,
				title: "Links 1Doc",
				content: {
					type: 'iframe',
					url: t.signUrl('./section.html', {
						arg: claimed_objs,
					}),
					height: 230,
				},
			}]
		} else {
			return [];
		}
		
	},
	'format-url': async function(t, opts){
		if (opts.url.indexOf('https://sergas.1doc.com.br/') !== 0)
			throw t.NotHandled()

		const regex = /hash=([0-9A-F]{24})/
		let match = opts.url.match(regex)
		if (!match || match.length < 2)
			throw t.NotHandled()

		let hash = match[1]

		let server = ''
		let token = ''
		try{
			server = await  t.loadSecret('server')
			token = await  t.loadSecret('token')
		}catch(error) {}
		
		let format_url = () => {
			return {
				text: `1Doc ${hash}`,
				subtext: opts.url,
				actions: [{
					text: 'Detalhes',
					callback: (t) => {
						t.modal({
							title: `Detalhes ${hash}`,
							url: `https://t1d.sergipegas.com.br:5000/hash/${hash}?api_key=${token}`,
							height: 700,
							accentColor: '#0079BF',
							fullscree: false,
						})
					},
				}],
			}
		}
		return format_url()
	},
	"card-detail-badges": function (t, opts) {
		return {
			refresh: 3600,
			title: "Status 1Doc",
			dynamic: async () => {
				query = await t.card("attachments");
				if (query.attachments.length){
					
					const processed = query.attachments.map((attachment) => {
					
						const first_test = attachment.url.indexOf('https://sergas.1doc.com.br/') == 0;
						
						const regex = /hash=([0-9A-F]{24})/;
						const match = attachment.url.match(regex);
			
						return {
							'valid': first_test && !!match && match.length > 1,
							'hash': match[1],
							'url': attachment.url,
						}
					});
					
					const claimed_objs = processed.filter(e => e.valid);
					const claimed = claimed_objs.map(e => e.hash);

					// Get server and token values
					let server = ''
					let token = ''
					try{
						server = await t.loadSecret('server')
						token = await t.loadSecret('token')
					}catch(error) {}

					if (claimed_objs.length > 0) {
						let hash =  claimed[0];
						try{
							data = await fetch(`https://t1d.sergipegas.com.br:5000/badge/${hash}?api_key=${token}`);
							return data.json()
						} catch (error){}
					}
				}
				return {
					text: "No information found!",
					color: "light-gray"
				};
				
			}
		}
	},
	'show-settings': function(t, options){
		// when a user clicks the gear icon by your Power-Up in the Power-Ups menu
		// what should Trello show. We highly recommend the popup in this case as
		// it is the least disruptive, and fits in well with the rest of Trello's UX
		return t.popup({
		  title: 'Custom Fields Settings',
		  url: './settings.html',
		  height: 216 // we can always resize later
		});
	  }
})
